import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const fetchData = async () => {
  const loginData = JSON.parse(localStorage.getItem("logindata"));
  const token = loginData?.accessToken;
  const ReportId = localStorage.getItem("reportID");

  try {
    const response = await fetch(
      `https://usfarmdata.org/fastapi/email_function/?operation=getschedulestats`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          Scheduleid: ReportId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null; // Handle error
  }
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-2 shadow-lg rounded">
        {payload.map((item) => (
          <p key={item.name} style={{ color: item.color }}>
            {item.name}: {item.value}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const ScheduleReport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const ReportName = localStorage.getItem("reportName");

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      setError(false);

      const apiData = await fetchData();
      if (apiData) {
        const chartData = apiData.map((item, index) => ({
          Delivered: item.Delivered || 0,
          NotDelivered: item.NotDelivered || 0,
          Processing: item.Processing || 0,
          Clicks: item.Clicks || 0,
          Opens: item.Opens || 0,
          Bounce: item.Bounce || 0,
          Unsubscribed: item.Unsubscribed || 0,
        }));
        setData(chartData);
      } else {
        setError(true);
      }
      setLoading(false);
    };

    getData();
  }, []);

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return (
      <div className="text-center mt-8 text-red-500">
        Failed to load data. Please try again.
      </div>
    );
  }

  return (
    <div className="w-11/12 mx-auto mt-8">
      <div className="bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-xl font-semibold text-center mb-6">
          {ReportName || "Schedule Report"}
        </h2>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data} style={{ backgroundColor: "transparent" }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#e5e5e5" />

            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <Tooltip content={<CustomTooltip />} />
            <Legend />

            <Bar
              yAxisId="left"
              dataKey="Delivered"
              fill="#FF6347"
              name="Delivered"
            />
            <Bar
              yAxisId="left"
              dataKey="NotDelivered"
              fill="#FFD700"
              name="Not Delivered"
            />
            <Bar
              yAxisId="left"
              dataKey="Processing"
              fill="#32CD32"
              name="Processing"
            />
            <Bar
              yAxisId="right"
              dataKey="Clicks"
              fill="#1E90FF"
              name="Clicks"
            />
            <Bar yAxisId="right" dataKey="Opens" fill="#8A2BE2" name="Opens" />
            <Bar
              yAxisId="right"
              dataKey="Bounce"
              fill="#FF1493"
              name="Bounce"
            />
            <Bar
              yAxisId="right"
              dataKey="Unsubscribed"
              fill="#FF4500"
              name="Unsubscribed"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ScheduleReport;
