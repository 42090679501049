import React, { useEffect, useState } from "react";
import { Card, Button, Collapse, Typography, Space } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { Container, Box } from "@lib/Layout";
const { Panel } = Collapse;
const { Title, Text } = Typography;
const ZoomCaller = () => {
  const [responseMessage, setResponseMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [viewMoreData, setViewMoreData] = useState(null);
  const [viewMoreLoading, setViewMoreLoading] = useState(false);
  const [viewMoreError, setViewMoreError] = useState(null);
  console.log(responseMessage, "responseMessage");

  useEffect(() => {
    const storedData = localStorage.getItem("ZoomCallEvent");
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const Token = loginData ? loginData.accessToken : null;

    if (storedData && Token) {
      const localEvent = JSON.parse(storedData);

      if (localEvent?.caller) {
        const rawNumber = localEvent.caller;
        const cleanedNumber = rawNumber.replace(/\D/g, "");
        const formattedNumber =
          cleanedNumber.length > 10 ? cleanedNumber.slice(-10) : cleanedNumber;

        console.log(formattedNumber, "formattedNumber");
        setLoading(true);
        setError(null);

        fetch(
          "https://usfarmdata.org/fastapi/zoom_function?operation=checkphone",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Token}`,
            },
            body: JSON.stringify({ Phone: formattedNumber }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            console.log("Response from API:", data);

            setResponseMessage(data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error during fetch:", error);
            setError(error.message);
            setLoading(false);
          });
      } else {
        console.error("Caller information is missing in localEvent");
      }
    } else {
      console.error("No ZoomCallEvent or access token found in localStorage");
    }
  }, []);

  const handleViewMore = () => {
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const storedData = localStorage.getItem("ZoomCallEvent");
    const Token = loginData ? loginData.accessToken : null;

    if (Token && responseMessage && storedData) {
      const localEvent = JSON.parse(storedData);
      const rawNumber = localEvent.caller;

      const cleanedNumber = rawNumber.replace(/\D/g, "");
      const formattedNumber =
        cleanedNumber.length > 10 ? cleanedNumber.slice(-10) : cleanedNumber;

      const bodyData = {
        Phone: formattedNumber,
        Rec_no: responseMessage.Rec_no,
        DBName: responseMessage.DBName,
      };

      setViewMoreLoading(true);
      setViewMoreError(null);

      fetch(
        "https://usfarmdata.org/fastapi/zoom_function?operation=getphonedetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify(bodyData),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Response from getPhoneDetails API:", data);
          setViewMoreData(data);
          setViewMoreLoading(false);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
          setViewMoreError(error.message);
          setViewMoreLoading(false);
        });
    } else {
      console.error("Required data for View More is missing");
    }
  };

  const actions = [<Button onClick={handleViewMore}>View More</Button>];

  return (
    <div className="px-8 py-2 bg-sky-50 h-full">
      <Container align={loading || error ? "both" : ""} className="gap-2">
        {loading && (
          <>
            <div role="status">
              <svg
                aria-hidden="true"
                className="inline w-8 h-8 text-gray-200 animate-spin fill-green-500"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
            <div>Fetching caller Details...</div>
          </>
        )}
        {error && <div>{error}</div>}

        {responseMessage && !loading && !error && (
          <Box>
            <Card title="Caller Information">
              {responseMessage.Profile && responseMessage.Profile.length > 0 ? (
                responseMessage.Profile.map((profile, index) => (
                  <div key={index}>
                    <Text strong>First Name: </Text> {profile.firstname}
                    <br />
                    <Text strong>Last Name: </Text> {profile.lastname}
                    <br />
                    <Text strong>Company: </Text> {profile.company}
                    <br />
                    <Text strong>Address: </Text> {profile.address}
                    <br />
                    <Text strong>City: </Text> {profile.city}
                    <br />
                    <Text strong>State: </Text> {profile.state}
                    <br />
                    <Text strong>Zip Code: </Text> {profile.zipcode}
                    <br />
                    <Text strong>Email: </Text> {profile.email}
                    <br />
                  </div>
                ))
              ) : (
                <Text>No profile data available.</Text>
              )}
            </Card>
          </Box>
        )}

        {viewMoreError && (
          <Box flexible className="bg-white rounded-md border">
            <Container align="both">
              <span>{viewMoreError}</span>
            </Container>
          </Box>
        )}
        {viewMoreLoading && (
          <Box flexible className="bg-white rounded-md border">
            <Container align="both">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 text-gray-200 animate-spin fill-green-500"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            </Container>
          </Box>
        )}
        {viewMoreData && !viewMoreLoading && !viewMoreError && (
          <Box flexible className="bg-white rounded-md  p-2">
            <Collapse
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              collapsible="header"
              style={{
                border: "1px solid rgb(226 232 240)",
                background: "none",
              }}
            >
              <Panel header="Profile" key="1">
                {viewMoreData.Profile && viewMoreData.Profile.length > 0 ? (
                  viewMoreData.Profile.map((profile, index) => (
                    <div key={index}>
                      <Text strong>First Name: </Text> {profile.firstname}
                      <br />
                      <Text strong>Last Name: </Text> {profile.lastname}
                      <br />
                      <Text strong>Company: </Text> {profile.company}
                      <br />
                      <Text strong>Address: </Text> {profile.address}
                      <br />
                      <Text strong>City: </Text> {profile.city}
                      <br />
                      <Text strong>State: </Text> {profile.state}
                      <br />
                      <Text strong>Zip Code: </Text> {profile.zipcode}
                      <br />
                      <Text strong>Email: </Text> {profile.email}
                      <br />
                    </div>
                  ))
                ) : (
                  <Text>No profile data available.</Text>
                )}
              </Panel>

              <Panel header="Business" key="2">
                {viewMoreData.Business ? (
                  <Text>{viewMoreData.Business}</Text>
                ) : (
                  <Text>No business data available.</Text>
                )}
              </Panel>

              <Panel header="Email" key="3">
                {viewMoreData.Email ? (
                  <Text>{viewMoreData.Email}</Text>
                ) : (
                  <Text>No email data available.</Text>
                )}
              </Panel>

              <Panel header="Equine" key="4">
                {viewMoreData.Equine ? (
                  <Text>{viewMoreData.Equine}</Text>
                ) : (
                  <Text>No equine data available.</Text>
                )}
              </Panel>

              <Panel header="Farm" key="5">
                {viewMoreData.Farm ? (
                  <Text>{viewMoreData.Farm}</Text>
                ) : (
                  <Text>No farm data available.</Text>
                )}
              </Panel>
            </Collapse>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default ZoomCaller;
