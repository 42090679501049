import React from "react";
import DashboardLogo from "../../../images/Dashboarddesign.png";
const Dashboard = () => {
  return (
    <div>
      <img src={DashboardLogo} alt="google logo" />
    </div>
  );
};

export default Dashboard;
