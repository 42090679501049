import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { Container, Box } from "@lib/Layout";
import { Form, Input, Button, Upload, Modal, message, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const QuillEditor = () => {
  const [editorContent, setEditorContent] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isCodeView, setIsCodeView] = useState(false);

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const checkIfEditorIsEmpty = () => {
    const trimmedContent = editorContent.trim();
    return !trimmedContent;
  };

  const showHtmlModal = () => {
    if (checkIfEditorIsEmpty()) {
      message.warning("Editor is empty. Please write something.");
      return;
    }
    setIsModalVisible(true);
  };

  const saveTemplate = async () => {
    try {
      await form.validateFields();
      if (checkIfEditorIsEmpty()) {
        message.warning("Editor is empty. Please write something.");
        return;
      }

      const loginData = JSON.parse(localStorage.getItem("logindata"));
      const token = loginData?.accessToken || "";
      const userId = loginData?.userId || "";

      setLoading(true);
      setError(null);

      const htmlDocument = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${form.getFieldValue("templateName")}</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 0;
                    padding: 20px;
                    background-color: #f8f8f8;
                }
            </style>
        </head>
        <body>
            ${editorContent}
        </body>
        </html>
      `;

      const requestData = {
        Userid: userId,
        TemplateName: form.getFieldValue("templateName"),
        Description: form.getFieldValue("description"),
        Templatetxt: htmlDocument,
        Filenamewithextension: `${form.getFieldValue("templateName")}.html`,
      };

      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=createtemplate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save template");
      }

      message.success("Template saved successfully!");
    } catch (err) {
      setError(err.message);
      message.error(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const saveAsHtmlFile = () => {
    if (checkIfEditorIsEmpty()) {
      message.warning("Editor is empty. Please write something.");
      return;
    }

    const htmlDocument = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>${form.getFieldValue("templateName")}</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 20px;
                  background-color: #f8f8f8;
              }
          </style>
      </head>
      <body>
          ${editorContent}
      </body>
      </html>
    `;

    const blob = new Blob([htmlDocument], { type: "text/html" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${form.getFieldValue("templateName")}.html`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileUpload = (file) => {
    if (file.size > 5 * 1024 * 1024) {
      message.error("File size exceeds 5MB. Please upload a smaller file.");
      return false;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      setEditorContent(content);
    };
    reader.readAsText(file);
    return false; // Prevent default upload behavior
  };

  const toggleCodeView = () => {
    setIsCodeView((prev) => !prev);
  };

  return (
    <div className="w-10/12 h-full" style={{ margin: "0 auto" }}>
      <Container
        className="gap-2 bg-white rounded-md"
        style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
      >
        <Box className="p-2 border-b">
          <Container align="vertical" alignBox="row">
            <Box flexible className="text-lg font-semibold text-slate-600">
              Create New Template
            </Box>
            <Button
              type="primary"
              ghost
              onClick={() => navigate("/email-campaign")}
            >
              View Available Templates
            </Button>
          </Container>
        </Box>
        <Box flexible className="py-2 px-4">
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="templateName"
                  label="Template Name"
                  rules={[
                    { required: true, message: "Please enter template name" },
                  ]}
                >
                  <Input placeholder="Enter template name" size="large" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    { required: true, message: "Please enter description" },
                  ]}
                >
                  <TextArea rows={2} placeholder="Enter description" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="flex gap-2 mb-2">
            <Upload
              accept=".html"
              beforeUpload={handleFileUpload}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} type="primary" ghost>
                Upload HTML
              </Button>
            </Upload>
            <Button onClick={toggleCodeView}>
              {isCodeView ? "Back to Editor" : "Switch to Edit Codeing"}
            </Button>
          </div>

          {isCodeView ? (
            <TextArea
              rows={18}
              value={editorContent}
              onChange={(e) => setEditorContent(e.target.value)}
              placeholder="Edit HTML code"
            />
          ) : (
            <Editor
              apiKey="3fgt6hne2j0nnnd81tpd9abdv1jk8iqqz9c85ki27xzbyvbd"
              value={editorContent}
              onEditorChange={handleEditorChange}
              init={{
                height: 380,
                menubar: false,
                plugins: [
                  "link",
                  "lists",
                  "image",
                  "media",
                  "table",
                  "textcolor",
                  "code",
                  "fontselect", // Ensure fontselect is added
                  "fontsizeselect", // Ensure fontsizeselect is added
                ],
                toolbar:
                  "undo redo | fontselect fontsizeselect | styleselect formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright | bullist numlist | link image",
                style_formats: [
                  { title: "Heading 1", format: "h1" },
                  { title: "Heading 2", format: "h2" },
                  { title: "Heading 3", format: "h3" },
                  { title: "Heading 4", format: "h4" },
                  { title: "Heading 5", format: "h5" },
                  { title: "Heading 6", format: "h6" },
                  { title: "Paragraph", format: "p" },
                  { title: "Blockquote", format: "blockquote" },
                  { title: "Preformatted", format: "pre" },
                ],
                textcolor_map: [
                  "000000",
                  "Black",
                  "FF0000",
                  "Red",
                  "00FF00",
                  "Green",
                  "0000FF",
                  "Blue",
                  "FFFF00",
                  "Yellow",
                  "FFA500",
                  "Orange",
                  "800080",
                  "Purple",
                  "FFFFFF",
                  "White",
                ],
                font_formats:
                  "Arial=arial,helvetica,sans-serif; \
                Comic Sans MS=comic sans ms,sans-serif; \
                Courier New=courier new,courier; \
                Georgia=georgia,palatino; \
                Tahoma=tahoma,arial,helvetica,sans-serif; \
                Times New Roman=times new roman,times; \
                Verdana=verdana,geneva,sans-serif;",
                fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
              }}
            />
          )}
        </Box>
        <Box className="border-t p-2">
          <div className="flex gap-2">
            <Button
              type="primary"
              loading={loading}
              onClick={saveTemplate}
              disabled={checkIfEditorIsEmpty()}
            >
              Save Template
            </Button>
            <Button
              type="primary"
              ghost
              onClick={showHtmlModal}
              disabled={checkIfEditorIsEmpty()}
            >
              Preview as HTML
            </Button>
            <Button
              type="primary"
              ghost
              onClick={saveAsHtmlFile}
              disabled={checkIfEditorIsEmpty()}
            >
              Download
            </Button>
          </div>
        </Box>
      </Container>
      <Modal
        title="HTML Output"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width={800}
      >
        <div dangerouslySetInnerHTML={{ __html: editorContent }} />
      </Modal>
    </div>
  );
};

export default QuillEditor;
