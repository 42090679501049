import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Login from "./pages/Login/Login";
import RegistrationForm from "./pages/Login/RegistrationForm";
import ContactMangementHome from "./pages/contactMangement/ContactMangementHome/ContactMangementHome";
import ListMangementHome from "./pages/listMangement/ListMangementHome/ListMangementHome";
import UserManagementHome from "./pages/userMangement/UserManagementHome/UserManagementHome";
import SettingsHome from "./pages/settings/SettingsHome/SettingsHome";
import BookMark from "./pages/listMangement/BookMark/BookMark";
import ListCenter from "./pages/listMangement/ListCenter/ListCenter";
import Dashboard from "./pages/listMangement/DashBoard/DashBoard";
import CustomQuryBuilder from "./pages/contactMangement/CustomQuryBuilder/CustomQuryBuilder";
import ForgotPasswordPage from "./pages/Login/ForgotPasswordPage/ForgotPasswordPage";
import ListUser from "./pages/userMangement/ListUser/ListUser";
import EditUser from "./pages/userMangement/EditUserInformation/EditUser";
import AddUser from "./pages/userMangement/AddUser.js/AddUser";
import RolePage from "./pages/settings/roles/RolePage/RolePage";
import Profile from "./pages/settings/Profile/Profile";
import Password from "./pages/settings/Password/Password";
import BookmarkSettings from "./pages/settings/BookmarkSettings/BookmarkSettings";
import TeamMembers from "./pages/settings/TeamMembers/TeamMembers";
import SearchEngine from "./pages/SearchEngine/SearchEngine";
import { WindowWidthProvider } from "./context/WindowContext";
import useNetworkStatus from "./hooks/useNetworkStatus";
import NetworkErrorPage from "./pages/NetworkErrorPage/NetworkErrorPage";
import TripEmailCampaign from "./pages/TripEmailCampaign/TripEmailCampaign";
import CreateTemplate from "./pages/TripEmailCampaign/CreateTemplate/CreateTemplate";
import ZoomCaller from "./pages/ZoomCaller/ZoomCaller";
import ScheduleReport from "./pages/TripEmailCampaign/ScheduleReport/ScheduleReport";
import PostCardHome from "./pages/postCard/PostCardHome/PostCardHome";
const isAuthenticated = () => {
  const profile = localStorage.getItem("profile");
  const userdata = localStorage.getItem("logindata");

  if (profile) {
    const user = JSON.parse(profile);
    if (user && user.verified_email) {
      return true;
    }
  }

  if (userdata) {
    const user = JSON.parse(userdata);
    if (user && user.accessToken && user.roleName) {
      return true;
    }
  }

  return false;
};

const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

const AppContainer = () => {
  const isOnline = useNetworkStatus(); // Use network status hook

  return (
    <WindowWidthProvider>
      <Router>
        <Routes>
          {/* If user is offline, show the Network Error Page */}
          {!isOnline ? (
            <Route path="*" element={<NetworkErrorPage />} />
          ) : (
            <>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              >
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <ListMangementHome />
                    </ProtectedRoute>
                  }
                />
                <Route
                  element={
                    <ProtectedRoute>
                      <ListMangementHome />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path="bookmark"
                    element={
                      <ProtectedRoute>
                        <BookMark />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="listcenter"
                    element={
                      <ProtectedRoute>
                        <ListCenter />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="usermanagement"
                    element={
                      <ProtectedRoute>
                        <UserManagementHome />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="contacts"
                    element={
                      <ProtectedRoute>
                        <ContactMangementHome />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path="gmail-activies"
                    element={
                      <ProtectedRoute>
                        <SearchEngine />
                      </ProtectedRoute>
                    }
                  /> */}
                  <Route
                    path="email-campaign"
                    element={
                      <ProtectedRoute>
                        <TripEmailCampaign />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="postcard"
                    element={
                      <ProtectedRoute>
                        <PostCardHome />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="schedulereport"
                    element={
                      <ProtectedRoute>
                        <ScheduleReport />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="livecaller-details"
                    element={
                      <ProtectedRoute>
                        <ZoomCaller />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="createtemplate"
                    element={
                      <ProtectedRoute>
                        <CreateTemplate />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    index
                    path="dashboard"
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    index
                    path="filter"
                    element={
                      <ProtectedRoute>
                        <CustomQuryBuilder />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="listuser"
                    element={
                      <ProtectedRoute>
                        <ListUser />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="adduser"
                    element={
                      <ProtectedRoute>
                        <AddUser />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="edituser"
                    element={
                      <ProtectedRoute>
                        <EditUser />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="settings"
                    element={
                      <ProtectedRoute>
                        <SettingsHome />
                      </ProtectedRoute>
                    }
                  >
                    <Route index element={<Navigate to="personal/profile" />} />
                    <Route path="personal/profile" element={<Profile />} />
                    <Route path="personal/password" element={<Password />} />
                    <Route
                      path="company/team-members"
                      element={<TeamMembers />}
                    />
                    <Route path="company/roles" element={<RolePage />} />
                    <Route
                      path="company/bookmark"
                      element={<BookmarkSettings />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
              <Route path="/registration" element={<RegistrationForm />} />
              <Route path="*" element={<NotFound />} />
            </>
          )}
        </Routes>
      </Router>
    </WindowWidthProvider>
  );
};

export default AppContainer;
