import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  message,
  Row,
  Col,
  Radio,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Container, Box } from "@lib/Layout";

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const PostCardHome = () => {
  const [form] = Form.useForm();
  const [selectedSize, setSelectedSize] = useState("standard");
  const [selectedOption, setSelectedOption] = useState("create");

  const sizeMap = {
    standard: { width: 1875, height: 1350 }, // 6.25" x 4.5" at 300 dpi
    jumbo: { width: 2625, height: 1875 }, // 8.75" x 6.25" at 300 dpi
    colossal: { width: 3375, height: 1875 }, // 11.25" x 6.25" at 300 dpi
    eddm1: { width: 1350, height: 3675 }, // 4.5" x 12.25" at 300 dpi
    eddm2: { width: 1875, height: 3375 }, // 6.25" x 11.25" at 300 dpi
    eddm3: { width: 1875, height: 3675 }, // 6.25" x 12.25" at 300 dpi
    eddm4: { width: 1950, height: 2625 }, // 6.5" x 8.75" at 300 dpi
    personalized1: { width: 1875, height: 1350 },
    personalized2: { width: 2625, height: 1875 },
    personalized3: { width: 3375, height: 1875 },
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = (values) => {
    console.log("Form Values:", values);
    message.success("Form submitted successfully!");
  };

  const validateImageSize = async (file, postcardSize) => {
    const expectedSize = sizeMap[postcardSize];
    if (!expectedSize) {
      message.error("Invalid postcard size selected!");
      return Upload.LIST_IGNORE;
    }

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const { width, height } = img;
        URL.revokeObjectURL(img.src);
        if (width !== expectedSize.width || height !== expectedSize.height) {
          message.error(
            `Image dimensions must be ${expectedSize.width / 300}" x ${
              expectedSize.height / 300
            }" inches at 300 dpi.`
          );
          reject(Upload.LIST_IGNORE);
        } else {
          resolve(true);
        }
      };
      img.onerror = () => {
        message.error("Unable to validate image dimensions.");
        reject(Upload.LIST_IGNORE);
      };
    });
  };

  const handlePostcardSizeChange = (value) => {
    setSelectedSize(value);
  };

  const validateLogo = async (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const { width, height } = img;
        URL.revokeObjectURL(img.src);

        if (width !== 300 || height !== 300) {
          message.error(
            "Logo dimensions must be 300px x 300px, and image must be 300 DPI."
          );
          reject(Upload.LIST_IGNORE);
        } else {
          resolve(true);
        }
      };
      img.onerror = () => {
        message.error("Unable to validate logo dimensions.");
        reject(Upload.LIST_IGNORE);
      };
    });
  };

  const getImageRequirementMessage = () => {
    const size = sizeMap[selectedSize];
    if (size) {
      return `Image dimensions must be ${size.width / 300}" x ${
        size.height / 300
      }" inches at 300 dpi.`;
    }
    return "Select a postcard size to see the required dimensions.";
  };

  return (
    <Container alignBox="row" className="gap-2 p-2 bg-sky-50">
      <Box flexible className="bg-white rounded-md p-2">
        <Container>
          {" "}
          <Box className="border-b">
            {" "}
            <h2 className="pb-4 pt-2 text-2xl">Postcard </h2>
          </Box>
          <Box flexible scroll="vertical" className="pt-4 px-2">
            <Container className="gap-4">
              <Box>
                <div className="flex items-center gap-5">
                  <div
                    className={`p-4 border rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer ${
                      selectedOption === "create"
                        ? "border-blue-500  border-2"
                        : "border-gray-300"
                    }`}
                    onClick={() =>
                      handleOptionChange({ target: { value: "create" } })
                    }
                  >
                    <input
                      type="radio"
                      value="create"
                      checked={selectedOption === "create"}
                      onChange={handleOptionChange}
                      className="hidden"
                    />
                    <b className="text-lg">Create Postcard</b>
                    <div className="text-gray-500 text-sm">
                      Design and create a new postcard.
                    </div>
                  </div>
                  <div
                    className={`p-4 border rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer ${
                      selectedOption === "existing"
                        ? "border-blue-500  border-2"
                        : "border-gray-300"
                    }`}
                    onClick={() =>
                      handleOptionChange({ target: { value: "existing" } })
                    }
                  >
                    <input
                      type="radio"
                      value="existing"
                      checked={selectedOption === "existing"}
                      onChange={handleOptionChange}
                      className="hidden"
                    />
                    <b className="text-lg">Choose Postcard</b>
                    <div className="text-gray-500 text-sm">
                      Upload and use an existing postcard design.
                    </div>
                  </div>
                </div>
              </Box>
              <Box flexible>
                {selectedOption === "create" && (
                  <Form
                    layout="vertical"
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{ postcardSize: "standard" }}
                    style={{ height: "100%" }}
                  >
                    <Container>
                      <Box flexible scroll="vertical">
                        {" "}
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              label="Name of the Company"
                              tooltip="Enter the official name of your company."
                              name="companyName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the company name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter company name"
                                size="large"
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              label="Phone Number"
                              name="phoneNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the phone number",
                                },
                                {
                                  pattern: /^[0-9]{10}$/,
                                  message: "Enter a valid 10-digit number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter phone number"
                                size="large"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              label="URL"
                              name="url"
                              rules={[
                                {
                                  type: "url",
                                  message: "Please enter a valid URL",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter website URL"
                                size="large"
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item label="Upload Logo (JPG/PNG)">
                              <Upload
                                beforeUpload={async (file) => {
                                  try {
                                    const isValidTypeAndSize =
                                      (file.type === "image/jpeg" ||
                                        file.type === "image/png") &&
                                      file.size <= 2 * 1024 * 1024;

                                    if (!isValidTypeAndSize) {
                                      message.error(
                                        "Logo must be JPG/PNG and less than 2MB!"
                                      );
                                      return Upload.LIST_IGNORE;
                                    }

                                    await validateLogo(file);
                                    return true;
                                  } catch {
                                    return Upload.LIST_IGNORE;
                                  }
                                }}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Upload Logo
                                </Button>
                                <div>
                                  {" "}
                                  <small style={{ color: "#888" }}>
                                    Logo dimensions must be 300px x 300px, and
                                    image must be 300 dpi.
                                  </small>
                                </div>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              label="Copy Front"
                              name="copyFront"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter the copy front",
                                },
                              ]}
                            >
                              <TextArea
                                placeholder="Enter the copy front"
                                autoSize={{
                                  minRows: 3,
                                  maxRows: 5,
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Copy Back"
                              name="copyback"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter the copy back",
                                },
                              ]}
                            >
                              <TextArea
                                placeholder="Enter the copy back"
                                autoSize={{
                                  minRows: 3,
                                  maxRows: 5,
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              label="Postcard Size"
                              name="postcardSize"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a postcard size",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select a size"
                                size="large"
                                onChange={handlePostcardSizeChange}
                              >
                                <OptGroup label="Postcards">
                                  <Option value="standard">
                                    Standard Postcard (6" x 4.25")
                                  </Option>
                                  <Option value="jumbo">
                                    Jumbo Postcard (8.5" x 6")
                                  </Option>
                                  <Option value="colossal">
                                    Colossal Postcard (11" x 6")
                                  </Option>
                                </OptGroup>
                                <OptGroup label="EDDM Postcards">
                                  <Option value="eddm1">
                                    EDDM Postcard (4.25" x 12")
                                  </Option>
                                  <Option value="eddm2">
                                    EDDM Postcard (6" x 11")
                                  </Option>
                                  <Option value="eddm3">
                                    EDDM Postcard (6" x 12")
                                  </Option>
                                  <Option value="eddm4">
                                    EDDM Postcard (6.25" x 8.5")
                                  </Option>
                                </OptGroup>
                                <OptGroup label="Personalized Postcards">
                                  <Option value="personalized1">
                                    Standard Postcard (6" x 4.25")
                                  </Option>
                                  <Option value="personalized2">
                                    Jumbo Postcard (8.5" x 6")
                                  </Option>
                                  <Option value="personalized3">
                                    Colossal Postcard (11" x 6")
                                  </Option>
                                </OptGroup>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label="Image 1 (JPG/PNG)">
                              <Upload
                                beforeUpload={async (file) => {
                                  try {
                                    const isValidTypeAndSize =
                                      (file.type === "image/jpeg" ||
                                        file.type === "image/png") &&
                                      file.size <= 5 * 1024 * 1024;

                                    if (!isValidTypeAndSize) {
                                      message.error(
                                        "Image must be JPG/PNG and less than 5MB!"
                                      );
                                      return Upload.LIST_IGNORE;
                                    }

                                    await validateImageSize(
                                      file,
                                      form.getFieldValue("postcardSize")
                                    );
                                    return true;
                                  } catch {
                                    return Upload.LIST_IGNORE;
                                  }
                                }}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Upload Image
                                </Button>
                              </Upload>
                              <div>
                                <small style={{ color: "#888" }}>
                                  {getImageRequirementMessage()}
                                </small>
                              </div>
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item label="Image 2 (JPG/PNG)">
                              <Upload
                                beforeUpload={async (file) => {
                                  try {
                                    const isValidTypeAndSize =
                                      (file.type === "image/jpeg" ||
                                        file.type === "image/png") &&
                                      file.size <= 5 * 1024 * 1024;

                                    if (!isValidTypeAndSize) {
                                      message.error(
                                        "Image must be JPG/PNG and less than 5MB!"
                                      );
                                      return Upload.LIST_IGNORE;
                                    }

                                    await validateImageSize(
                                      file,
                                      form.getFieldValue("postcardSize")
                                    );
                                    return true;
                                  } catch {
                                    return Upload.LIST_IGNORE;
                                  }
                                }}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Upload Image
                                </Button>
                              </Upload>
                              <div>
                                <small style={{ color: "#888" }}>
                                  {getImageRequirementMessage()}
                                </small>
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Box>
                      <Box className="border-t pt-3">
                        {" "}
                        <Form.Item style={{ marginBottom: "0" }}>
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </Form.Item>
                      </Box>
                    </Container>
                  </Form>
                )}

                {selectedOption === "existing" && (
                  <Form
                    layout="vertical"
                    onFinish={handleSubmit}
                    style={{ height: "100%" }}
                  >
                    <Container>
                      <Box flexible>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              label="Postcard Size"
                              name="postcardSize"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a postcard size",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select a size"
                                size="large"
                                onChange={handlePostcardSizeChange}
                              >
                                <OptGroup label="Postcards">
                                  <Option value="standard">
                                    Standard Postcard (6" x 4.25")
                                  </Option>
                                  <Option value="jumbo">
                                    Jumbo Postcard (8.5" x 6")
                                  </Option>
                                  <Option value="colossal">
                                    Colossal Postcard (11" x 6")
                                  </Option>
                                </OptGroup>
                                <OptGroup label="EDDM Postcards">
                                  <Option value="eddm1">
                                    EDDM Postcard (4.25" x 12")
                                  </Option>
                                  <Option value="eddm2">
                                    EDDM Postcard (6" x 11")
                                  </Option>
                                  <Option value="eddm3">
                                    EDDM Postcard (6" x 12")
                                  </Option>
                                  <Option value="eddm4">
                                    EDDM Postcard (6.25" x 8.5")
                                  </Option>
                                </OptGroup>
                              </Select>
                            </Form.Item>

                            <Form.Item label="Upload Existing Postcard (JPG/PNG)">
                              <Upload
                                beforeUpload={async (file) => {
                                  try {
                                    const isValidTypeAndSize =
                                      (file.type === "image/jpeg" ||
                                        file.type === "image/png") &&
                                      file.size <= 5 * 1024 * 1024;

                                    if (!isValidTypeAndSize) {
                                      message.error(
                                        "Image must be JPG/PNG and less than 5MB!"
                                      );
                                      return Upload.LIST_IGNORE;
                                    }

                                    await validateImageSize(file, selectedSize);
                                    return true;
                                  } catch {
                                    return Upload.LIST_IGNORE;
                                  }
                                }}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Upload Image
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Box>
                      <Box className="border-t pt-4">
                        <Form.Item style={{ marginBottom: "0" }}>
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </Form.Item>
                      </Box>
                    </Container>
                  </Form>
                )}
              </Box>
            </Container>
          </Box>
        </Container>
      </Box>
      <Box className="w-2/5 bg-white rounded-md">
        <div class="w-64 h-48 overflow-hidden">
          <img
            src="your-image.jpg"
            alt="Description"
            class="w-full h-full object-cover"
          />
        </div>
      </Box>
    </Container>
  );
};

export default PostCardHome;
