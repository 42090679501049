import React, { useEffect, useState } from "react";
import { Container, Box } from "@lib/Layout";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import PlaylistAddCheckTwoToneIcon from "@mui/icons-material/PlaylistAddCheckTwoTone";
import SignalCellularAltTwoToneIcon from "@mui/icons-material/SignalCellularAltTwoTone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CampaignIcon from "@mui/icons-material/Campaign";
import styled from "@emotion/styled";

// Define custom styled components for icons
const CustomBookmarkIcon = styled(BookmarkOutlinedIcon)`
  color: #3765bf;
  vertical-align: sub;
`;

const CustomPlaylistIcon = styled(PlaylistAddCheckTwoToneIcon)`
  color: #3765bf;
  vertical-align: sub;
`;

const CustomDashboardIcon = styled(SignalCellularAltTwoToneIcon)`
  color: #3765bf;
  vertical-align: sub;
`;

const CustomPostAddIcon = styled(PostAddIcon)`
  color: #3765bf;
  vertical-align: sub;
`;
const CustomCampaignIcon = styled(CampaignIcon)`
  color: #3765bf;
  vertical-align: sub;
`;
const ListMangementHome = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);

  useEffect(() => {
    // Redirect to "/listmanagement/dashboard" if current location is "/"
    if (location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [location, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
      if (window.innerWidth > 600) {
        setIsDropdownOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const findLink = (items) =>
      items.find((item) => item.url === location.pathname);

    // Set the selected link for both mobile and desktop views
    setSelectedLink(findLink(defaultItems) || findLink(mobileItems));
  }, [location]);

  const defaultItems = [
    {
      label: "Dashboard",
      url: "/dashboard",
      icon: <CustomDashboardIcon />,
    },
    {
      label: "List Center",
      url: "/listcenter",
      icon: <CustomPlaylistIcon />,
    },
    {
      label: "BookMark",
      url: "/bookmark",
      icon: <CustomBookmarkIcon />,
    },
    {
      label: "Email Campaign",
      url: "/email-campaign",
      icon: <CustomCampaignIcon />,
    },
    {
      label: "PostCard",
      url: "/postcard",
      icon: <CustomPostAddIcon />,
    },
  ];

  const mobileItems = [
    ...defaultItems,
    {
      label: "User Management",
      url: "/usermanagement",
      icon: <AccountCircleIcon />,
    },
    {
      label: "Contacts",
      url: "/contacts",
      icon: <GroupIcon />,
    },
    {
      label: "Settings",
      url: "/settings",
      icon: <SettingsIcon />,
    },
    {
      label: "Email Campaign",
      url: "/email-campaign",
      icon: <CustomCampaignIcon />,
    },
    {
      label: "PostCard",
      url: "/postcard",
      icon: <CustomPostAddIcon />,
    },
  ];

  const Lhs = () => {
    return (
      <Container
        scroll="vertical"
        isCover={false}
        alignBox="row"
        className="transition-all duration-400 ease-in-out h-full gap-5 px-3.5"
      >
        <Box>
          <ul className="flex flex-row gap-5 items-center">
            {!isMobileView ? (
              defaultItems.map((item, index) => (
                <li key={index}>
                  <NavLink
                    to={item.url}
                    end
                    className={({ isActive }) =>
                      isActive
                        ? "bg-blue-200 text-slate-900 px-5 py-2 block font-roboto font-normal text-sm border-t-4 border-t-blue-500"
                        : "text-slate-900 font-roboto font-normal px-5 py-2 block text-sm hover:bg-blue-300 border-t-4 border-t-transparent"
                    }
                  >
                    <div className="flex gap-1 items-center">
                      <div>{item.icon}</div>
                      <div>{item.label}</div>
                    </div>
                  </NavLink>
                </li>
              ))
            ) : (
              <>
                {selectedLink && (
                  <li>
                    <NavLink
                      to={selectedLink.url}
                      end
                      className={({ isActive }) =>
                        isActive
                          ? "bg-blue-200 text-slate-900 px-5 py-2 block font-roboto font-normal text-sm border-t-4 border-t-blue-500"
                          : "text-slate-900 font-roboto font-normal px-5 py-2 block text-sm hover:bg-blue-300 border-t-4 border-t-transparent"
                      }
                    >
                      <div className="flex gap-1 items-center">
                        <div>{selectedLink.icon}</div>
                        <div>{selectedLink.label}</div>
                      </div>
                    </NavLink>
                  </li>
                )}
                <li className="nav-more">
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="text-slate-900 font-roboto font-normal px-5 py-2 block text-sm hover:bg-blue-300 border-t-4 border-t-transparent"
                  >
                    More
                  </button>
                  {isDropdownOpen && (
                    <ul className="absolute bg-white border border-gray-300 mt-2 shadow-lg rounded-md overflow-hidden z-10">
                      {mobileItems
                        .filter((item) => item !== selectedLink)
                        .map((item, index) => (
                          <li key={index}>
                            <NavLink
                              to={item.url}
                              end
                              className={({ isActive }) =>
                                isActive
                                  ? "bg-blue-200 text-slate-900 px-5 py-2 block font-roboto font-normal text-sm border-t-4 border-t-blue-500"
                                  : "text-slate-900 font-roboto font-normal px-5 py-2 block text-sm hover:bg-blue-300 border-t-4 border-t-transparent"
                              }
                            >
                              <div className="flex gap-1 items-center">
                                <div>{item.icon}</div>
                                <div>{item.label}</div>
                              </div>
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  )}
                </li>
              </>
            )}
          </ul>
        </Box>
      </Container>
    );
  };

  return (
    <Container alignBox="column" className="gap-2">
      <Box
        style={{ boxShadow: "rgb(11 11 12 / 29%) 0px 10px 10px -10px" }}
        className="bg-gray-100"
      >
        <Lhs />
      </Box>
      <Box flexible scroll="vertical">
        <Outlet />
      </Box>
    </Container>
  );
};

export default ListMangementHome;
