import { useState } from "react";
import { message } from "antd";

const useAddSchedule = () => {
  const [addScheduleLoading, setAddScheduleLoading] = useState(false);
  const [addRecurringScheduleLoading, setAddRecurringScheduleLoading] = useState(false);

  const addSchedule = async (scheduleData, operationType = "addschedule") => {
    const isRecurring = operationType === "addrecurringschedule";
    if (isRecurring) {
      setAddRecurringScheduleLoading(true);
    } else {
      setAddScheduleLoading(true);
    }

    try {
      const loginData = JSON.parse(localStorage.getItem("logindata"));
      const token = loginData ? loginData.accessToken : null;

      const response = await fetch(
        `https://usfarmdata.org/fastapi/email_function/?operation=${operationType}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(scheduleData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json(); 
        throw new Error(
          errorData.detail || `API Error: ${response.statusText}`
        );
      }

      const result = await response.json();
      message.success("Schedule added successfully!");
      localStorage.removeItem("scheduledData");
      return result;
    } catch (error) {
      console.error("Error in addSchedule:", error.message);
      throw error;
    } finally {
      if (isRecurring) {
        setAddRecurringScheduleLoading(false);
      } else {
        setAddScheduleLoading(false);
      }
    }
  };

  return { addSchedule, addScheduleLoading, addRecurringScheduleLoading };
};

export default useAddSchedule;
