import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLogin } from "../../context/LoginContext";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import PortalLogo from "../../images/newlogo.png";
import googleLogo from "../../images/google.png";
import backgroundImage from "../../images/homeBg.jpg";
import axios from "axios";
import { message, Tooltip } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [acceptInviteResponse, setAcceptInviteResponse] = useState(null);
  const navigate = useNavigate();
  const { login } = useLogin();
  const location = useLocation();
  const urlToken = new URLSearchParams(location.search).get("token");
  const TOKEN_EXPIRY_TIME = 24 * 60 * 60 * 1000;
  useEffect(() => {
    if (urlToken && !localStorage.getItem("welcomeMessageShown")) {
      message.info("Welcome to Us Farm Data Portal.");
      localStorage.setItem("welcomeMessageShown", "true");
      localStorage.setItem("urlToken", urlToken);
    }
  }, [urlToken]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    try {
      const formData = new FormData();
      formData.append("username", email);
      formData.append("password", password);

      const response = await fetch(
        "https://usfarmdata.org/fastapi/loginbypassword",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Login failed");
      }

      const data = await response.json();
      login(data);
      localStorage.setItem("logindata", JSON.stringify(data));
      localStorage.setItem("token", data.accessToken);
      message.success("Successfully logged in!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      setError(error.message || "Invalid credentials. Please try again.");
      message.error(error.message || "Invalid credentials. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLoginSuccess = (codeResponse) => {
    const accessToken = codeResponse.access_token;
    const expiryTime = new Date().getTime() + TOKEN_EXPIRY_TIME;
    localStorage.setItem("token_expiry_time", expiryTime);
    localStorage.setItem("g_token", JSON.stringify(accessToken));
    setUser(codeResponse);
  };

  const handleGoogleLoginError = (error) => {
    console.log("Google Login Failed:", error);
    setError("Google login failed. Please try again.");
    message.error("Google login failed. Please try again.");
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginError,
    // scope:
    //   "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    scope: [
      "openid", // Basic identity
      "email", // User's email
      "profile", // User's basic profile
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/gmail.readonly",
    ].join(" "),
  });

  useEffect(() => {
    if (user) {
      const fetchProfileAndAcceptInvite = async () => {
        setLoading(true);
        try {
          const profileResponse = await axios.get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
            {
              headers: {
                Authorization: `Bearer ${user.access_token}`,
                Accept: "application/json",
              },
            }
          );

          if (profileResponse.status !== 200) {
            throw new Error("Error fetching profile data.");
          }

          setProfile(profileResponse.data);

          const {
            email: googleEmail,
            given_name: googleUsername,
            verified_email: isEmailVerified,
          } = profileResponse.data;
          const verified = isEmailVerified ? "True" : "False";

          const bodyData = {
            invitetoken: urlToken || undefined,
            emailid: googleEmail,
            isemailverified: verified,
            username: googleUsername,
          };

          const acceptInviteResponse = await fetch(
            "https://usfarmdata.org/fastapi/accept_invite/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(bodyData),
            }
          );

          if (!acceptInviteResponse.ok) {
            const errorData = await acceptInviteResponse.json();
            throw new Error(errorData.detail || "Failed to accept the invite.");
          }

          const logindata = await acceptInviteResponse.json();
          localStorage.setItem("emailtoken", logindata.accesstoken);
          setAcceptInviteResponse(logindata);
          localStorage.setItem("logindata", JSON.stringify(logindata));
          localStorage.setItem("profile", JSON.stringify(profileResponse.data));

          navigate(urlToken ? "/settings/personal/profile" : "/dashboard");
          message.success(
            `Successfully ${urlToken ? "registered" : "logged in"} with Google!`
          );
        } catch (err) {
          console.error("Error during invite acceptance:", err);
          setError(err.message || "An error occurred. Please try again.");
          message.error(err.message || "An error occurred. Please try again.");
        } finally {
          setLoading(false);
        }
      };

      fetchProfileAndAcceptInvite();
    }
  }, [user, urlToken, navigate]);

  const handleForgotPassword = () => {
    navigate("/forgotpassword");
  };

  const handleSignUp = () => {
    navigate("/registration");
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <div className="from-cyan-500 to-blue-500 min-h-screen flex items-center justify-center w-full">
        <div
          className="bg-white w-96 rounded-lg px-8 py-6 max-w-md"
          style={{
            boxShadow:
              "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
          }}
        >
          <img
            src={PortalLogo}
            alt="Logo"
            className="mx-auto mb-4 w-[204px] h-[40px]"
          />
          {error && (
            <div className="mb-4 text-sm text-red-500 text-center">{error}</div>
          )}
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                UserName
              </label>
              <input
                type="text"
                id="email"
                className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Enter your UserName"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700  mb-2"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="*********"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                {password && (
                  <Tooltip
                    title={showPassword ? "Hide Password" : "Show Password"}
                  >
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-xs text-blue-500 leading-5"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeOutlined className="text-base" />
                      ) : (
                        <EyeInvisibleOutlined className="text-base" />
                      )}
                    </button>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="mb-6 text-left">
              <span
                className="text-blue-600 text-sm font-semibold cursor-pointer"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </span>
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#4e83eb] hover:bg-[#4e83eb] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Logging in...
                </div>
              ) : (
                "LOG IN"
              )}
            </button>
            {urlToken && urlToken.trim() !== "" && (
              <button
                type="button"
                onClick={handleSignUp}
                className="w-full mt-3 flex justify-center py-2 px-4 border rounded-md uppercase text-sm font-medium text-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign Up
              </button>
            )}
          </form>

          <div className="mt-3">
            <button
              type="button"
              onClick={googleLogin}
              className="w-full text-sky-700 flex gap-3 flex-row justify-center py-2 px-4 border rounded-md shadow-sm text-blue focus:ring-1 focus:ring-blue-500"
              disabled={loading}
            >
              <span>
                <img src={googleLogo} alt="google logo" className="h-6" />
              </span>
              Continue with Google
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
